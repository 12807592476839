<template>
    <div>
        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col sm="12">
                        <h3>
                            <span class="btn-inner--icon p-1">
                                <img
                                    src="img/icons/common/ai_stars.svg"
                                /> </span
                            >AI Feedback
                        </h3>
                    </b-col>
                </b-row>
                <b-row v-if="$apollo.loading">
                    <b-col
                        class="d-flex align-items-center justify-content-center"
                        
                    >
                        <h1>
                            <i class="fas fa-spinner fa-spin ml-1"></i>
                        </h1>
                    </b-col>
                </b-row>
                <b-row v-else>
                    <b-col
                        v-if="
                            idea.ai_feedback.length > 0 &&
                            idea.ai_feedback[0].response
                        "
                    >
                        <div v-html="idea.ai_feedback[0].response"></div>
                    </b-col>
                    <b-col v-else>
                        <p>No feedback yet, please check back later.</p>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
// Queries
import { GET_HACKATHON_IDEA_AI_FEEDBACK } from "@/graphql/queries";

// ORM
import { processIdeaRelayData, Idea } from "@/ORM/Hackathon/Idea.js";

export default {
    name: "HackathonAiIdeaFeedback",
    props: {
        idea_id: {
            type: String,
            description: "The id of the idea",
            default: null,
        },
    },
    apollo: {
        get_hackathon_idea_feedback: {
            query: GET_HACKATHON_IDEA_AI_FEEDBACK,
            result(res) {
                this.idea = processIdeaRelayData(res);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    idea_id: this.idea_id,
                };
            },

            update(data) {
                this.apollo_data.get_hackathon_idea_feedback = data;
            },
            skip: true,
        },
    },
    data() {
        return {
            apollo_data: {
                get_hackathon_idea_feedback: null,
            },
            idea: new Idea(),
        };
    },
    methods: {
        manage_get_hackathon_idea_feedback() {
            if (this.idea_id) {
                graph_utils.enable_query(
                    this.$apollo.queries.get_hackathon_idea_feedback
                );
            } else {
                graph_utils.disable_query(
                    this.$apollo.queries.get_hackathon_idea_feedback
                );
            }
        },
        get_idea_id() {
            if (this.$route.params.idea_id) {
                this.idea_id = this.$route.params.idea_id;
            } else {
                this.idea_id = null;
            }
            // TODO: reroute to "update" if id is found.
        },
    },
    mounted() {
        this.get_idea_id();
        this.manage_get_hackathon_idea_feedback();
    },
    watch: {
        idea_id() {
            this.manage_get_hackathon_idea_feedback();
        },
    },
};
</script>

<style></style>
